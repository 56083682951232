import * as React from 'react';

interface IRouterLoadingProps {
}

function RouterLoading(props: IRouterLoadingProps) {
    return (
        <div>
            Loading...
        </div>
    );
}

export default RouterLoading