import * as React from 'react';

export interface INotFoundProps {
}

export function NotFound(props: INotFoundProps) {
    return (
        <div>
            Page Not Found!
        </div>
    );
}
