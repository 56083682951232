import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import RouterLoading from 'components/RouterLoading'
import { NotFound } from 'views/not-found'

const PageLoading = <RouterLoading />
const LoadableComponent = (import_fn: (props: unknown) => Promise<any>) => loadable(import_fn, { fallback: PageLoading })

export const routes = <Router>
    <Route path='/' >
        <Switch>
            <Route exact path='/' component={LoadableComponent(() => import('views/home'))}></Route>
            <Route path='/login' component={LoadableComponent(() => import('views/login'))}></Route>
            <Route path='/order/list' component={LoadableComponent(() => import('views/order-list'))}></Route>
            <Route path='/center' component={LoadableComponent(() => import('views/center'))}></Route>
            <Route path='/*' component={NotFound}></Route>
        </Switch>
    </Route>
</Router>