import React from 'react';
// import logo from './logo.svg';
import { routes } from './Router'
import './App.css';


function App() {
  return (
    <div className="App">
      {routes}
    </div>
  );
}

export default App;
